.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;    
  }

  .modal-content h2 {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px 0px;
    padding: 5px;
}


  .modal-content {
    display: grid;
    place-items: center;
    position: fixed;
    text-align:center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2em;
    z-index: 200;
    background-color: #282c34;
    color: #fff;
    border-radius: 10px;
    font-family: 'poppins', sans-serif;
    font-weight: heavy;
    font-size: 18px;    
  }

  .modal-button-close {
    padding: 1rem;
    font-family: 'poppins', sans-serif;
    font-weight: medium;
    font-size: 18px;
    color: #fff;
    background-color: #8f9146;
    border-radius: 10px;
    outline: none;
    margin-top: 1rem;
  }

  