.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  width:  100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dropdown {
  width: 20vw;
  height: 5vh;
  font-size: 0.6em;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 12px auto;
  text-align: center;
}

.feedback-textarea {
  width: 50vw;
  height: 30vh;
  resize: none;
  border-radius: 8px;
  border: none;
  font-size: 0.8em;
  margin-top: 24px;
}

.submit-button {
  background-color: #8f9146;
  width: 20vw;
  height: 8vh;
  font-size: 0.8em;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 12px auto;
  display: block;
}


.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 6px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {

  .App-header {
    font-size: calc(8px + 4vmin);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .App-logo {
    height: 30vmin;
  }

  .label {
    padding: 3px;
    margin-bottom: 2px;
  }

  select {
    width: 100% !important;
    margin: 5px 0 !important;
    box-sizing: border-box !important;
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
  }

  .feedback-textarea {
    width: 80vw;
    height: 30vh;
    resize: none;
    border-radius: 8px;
    border: none;
    font-size: 0.8em;
    margin-top: 12px;
  }

  .submit-button {
    background-color: #8f9146;
    width: 40vw;
    height: 8vh;
    font-size: 0.8em;
    color: white;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    margin: 12px auto;
    display: block;
  }
}

